@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  padding: 0px 0px 0px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tooltips sometimes need to be properly formatted with newlines, this allows them to be so */
.tooltip {
  white-space: pre-wrap !important;
}

:root {
  --general-definition: #0000AA;
  --site-definition: #00812A;

  --grey: #707070;
  --softblue: #4c92cacb;
  --alp: #dc3545;
  --alp-mid: #ec7480;
  --alp-light: #f5a3ab;
  --alp-xlight: #f7c0c5;
  --alp-xxlight: #fadde0;
  --alp-xxxlight: #fdedef;
  --lnp: #1467CC;
  --lnp-mid: #5792da;
  --lnp-light: #87b2e7;
  --lnp-xlight: #abcaf0;
  --lnp-xxlight: #d6e6fa;
  --lnp-xxxlight: #ecf5ff;
  --lib: #1467CC;
  --lib-mid: #5792da;
  --lib-light: #87b2e7;
  --lib-xlight: #abcaf0;
  --lib-xxlight: #d6e6fa;
  --lib-xxxlight: #ecf5ff;
  --nat: #247422;
  --nat-mid: #5aa357;
  --nat-light: #7bc079;
  --nat-xlight: #a3e0a2;
  --nat-xxlight: #c9f0c8;
  --nat-xxxlight: #e2fae2;
  --grn: #68a800;
  --grn-mid: #8dc237;
  --grn-light: #b2d875;
  --grn-xlight: #cfe6ab;
  --grn-xxlight: #e9f5d6;
  --grn-xxxlight: #f9ffef;
  --oth: #838383;
  --oth-mid: #a5a5a5;
  --oth-light: #cacaca;
  --oth-xlight: #dddddd;
  --oth-xxlight: #f1f1f1;
  --oth-xxxlight: #f7f7f7;
  --uap: #c2b615;
  --uap-mid: #ebdf43;
  --uap-light: #f0e87c;
  --uap-xlight: #fcf7b1;
  --uap-xxlight: #fcf9d7;
  --uap-xxxlight: #fcf9e1;
  --on: #ff7f00;
  --on-mid: #ffab58;
  --on-light: #ffc388;
  --on-xlight: #ffd5aa;
  --on-xxlight: #fde6ce;
  --on-xxxlight: #faeee0;
  --kap: #9a593a;
  --kap-mid: #be8c75;
  --kap-light: #cfab9a;
  --kap-xlight: #dfc3b6;
  --kap-xxlight: #eedfd8;
  --kap-xxxlight: #f7eeea;
  --ind: #837083;
  --ind-mid: #a594a5;
  --ind-light: #b8aab8;
  --ind-xlight: #cec2ce;
  --ind-xxlight: #ddd3dd;
  --ind-xxxlight: #eee7ee;
  --indx: #707083;
  --indx-mid: #9594a5;
  --indx-light: #acaab8;
  --indx-xlight: #c3c2ce;
  --indx-xxlight: #d4d3dd;
  --indx-xxxlight: #e7e7ee;
}

.alp-bg {
  background-color: var(--alp) !important;
  color: #212529 !important;
}

.grn-bg {
  background-color: var(--grn) !important;
  color: #212529 !important;
}

.kap-bg {
  background-color: var(--kap) !important;
  color: #212529 !important;
}

.lib-bg {
  background-color: var(--lib) !important;
  color: #212529 !important;
}

.lnp-bg {
  background-color: var(--lnp) !important;
  color: #212529 !important;
}

.nat-bg {
  background-color: var(--nat) !important;
  color: #212529 !important;
}

.on-bg {
  background-color: var(--on) !important;
  color: #212529 !important;
}

.oth-bg {
  background-color: var(--oth) !important;
  color: #212529 !important;
}

.uap-bg {
  background-color: var(--uap) !important;
  color: #212529 !important;
}

.ind-bg {
  background-color: var(--ind) !important;
  color: #212529 !important;
}

.indx-bg {
  background-color: var(--indx) !important;
  color: #212529 !important;
}

.alp-bg-mid {
  background-color: var(--alp-mid) !important;
  color: #212529 !important;
}

.grn-bg-mid {
  background-color: var(--grn-mid) !important;
  color: #212529 !important;
}

.kap-bg-mid {
  background-color: var(--kap-mid) !important;
  color: #212529 !important;
}

.lib-bg-mid {
  background-color: var(--lib-mid) !important;
  color: #212529 !important;
}

.lnp-bg-mid {
  background-color: var(--lnp-mid) !important;
  color: #212529 !important;
}

.nat-bg-mid {
  background-color: var(--nat-mid) !important;
  color: #212529 !important;
}

.on-bg-mid {
  background-color: var(--on-mid) !important;
  color: #212529 !important;
}

.oth-bg-mid {
  background-color: var(--oth-mid) !important;
  color: #212529 !important;
}

.uap-bg-mid {
  background-color: var(--uap-mid) !important;
  color: #212529 !important;
}

.ind-bg-mid {
  background-color: var(--ind-mid) !important;
  color: #212529 !important;
}

.indx-bg-mid {
  background-color: var(--indx-mid) !important;
  color: #212529 !important;
}

.alp-bg-light {
  background-color: var(--alp-light) !important;
  color: #212529 !important;
}

.grn-bg-light {
  background-color: var(--grn-light) !important;
  color: #212529 !important;
}

.kap-bg-light {
  background-color: var(--kap-light) !important;
  color: #212529 !important;
}

.lib-bg-light {
  background-color: var(--lib-light) !important;
  color: #212529 !important;
}

.lnp-bg-light {
  background-color: var(--lnp-light) !important;
  color: #212529 !important;
}
.nat-bg-light {
  background-color: var(--nat-light) !important;
  color: #212529 !important;
}

.on-bg-light {
  background-color: var(--on-light) !important;
  color: #212529 !important;
}

.oth-bg-light {
  background-color: var(--oth-light) !important;
  color: #212529 !important;
}

.uap-bg-light {
  background-color: var(--uap-light) !important;
  color: #212529 !important;
}

.ind-bg-light {
  background-color: var(--ind-light) !important;
  color: #212529 !important;
}

.indx-bg-light {
  background-color: var(--indx-light) !important;
  color: #212529 !important;
}

.alp-bg-xlight {
  background-color: var(--alp-xlight) !important;
  color: #212529 !important;
}

.grn-bg-xlight {
  background-color: var(--grn-xlight) !important;
  color: #212529 !important;
}

.kap-bg-xlight {
  background-color: var(--kap-xlight) !important;
  color: #212529 !important;
}

.lib-bg-xlight {
  background-color: var(--lib-xlight) !important;
  color: #212529 !important;
}

.lnp-bg-xlight {
  background-color: var(--lnp-xlight) !important;
  color: #212529 !important;
}

.nat-bg-xlight {
  background-color: var(--nat-xlight) !important;
  color: #212529 !important;
}

.on-bg-xlight {
  background-color: var(--on-xlight) !important;
  color: #212529 !important;
}

.oth-bg-xlight {
  background-color: var(--oth-xlight) !important;
  color: #212529 !important;
}

.ind-bg-xlight {
  background-color: var(--ind-xlight) !important;
  color: #212529 !important;
}

.indx-bg-xlight {
  background-color: var(--indx-xlight) !important;
  color: #212529 !important;
}

.uap-bg-xlight {
  background-color: var(--uap-xlight) !important;
  color: #212529 !important;
}

.alp-bg-xxlight {
  background-color: var(--alp-xxlight) !important;
  color: #212529 !important;
}

.grn-bg-xxlight {
  background-color: var(--grn-xxlight) !important;
  color: #212529 !important;
}

.kap-bg-xxlight {
  background-color: var(--kap-xxlight) !important;
  color: #212529 !important;
}

.lib-bg-xxlight {
  background-color: var(--lib-xxlight) !important;
  color: #212529 !important;
}

.lnp-bg-xxlight {
  background-color: var(--lnp-xxlight) !important;
  color: #212529 !important;
}

.nat-bg-xxlight {
  background-color: var(--nat-xxlight) !important;
  color: #212529 !important;
}

.on-bg-xxlight {
  background-color: var(--on-xxlight) !important;
  color: #212529 !important;
}

.oth-bg-xxlight {
  background-color: var(--oth-xxlight) !important;
  color: #212529 !important;
}

.uap-bg-xxlight {
  background-color: var(--uap-xxlight) !important;
  color: #212529 !important;
}

.ind-bg-xxlight {
  background-color: var(--ind-xxlight) !important;
  color: #212529 !important;
}

.indx-bg-xxlight {
  background-color: var(--indx-xxlight) !important;
  color: #212529 !important;
}

.alp-bg-xxxlight {
  background-color: var(--alp-xxxlight) !important;
  color: #212529 !important;
}

.grn-bg-xxxlight {
  background-color: var(--grn-xxxlight) !important;
  color: #212529 !important;
}

.kap-bg-xxxlight {
  background-color: var(--kap-xxxlight) !important;
  color: #212529 !important;
}

.lib-bg-xxxlight {
  background-color: var(--lib-xxxlight) !important;
  color: #212529 !important;
}

.lnp-bg-xxxlight {
  background-color: var(--lnp-xxxlight) !important;
  color: #212529 !important;
}

.nat-bg-xxxlight {
  background-color: var(--nat-xxxlight) !important;
  color: #212529 !important;
}

.on-bg-xxxlight {
  background-color: var(--on-xxxlight) !important;
  color: #212529 !important;
}

.oth-bg-xxxlight {
  background-color: var(--oth-xxxlight) !important;
  color: #212529 !important;
}

.uap-bg-xxxlight {
  background-color: var(--uap-xxxlight) !important;
  color: #212529 !important;
}

.ind-bg-xxxlight {
  background-color: var(--ind-xxxlight) !important;
  color: #212529 !important;
}

.indx-bg-xxxlight {
  background-color: var(--indx-xxxlight) !important;
  color: #212529 !important;
}