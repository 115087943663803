.party-badge {
    border-radius: 5px;
    padding: 2px 4px 3px 4px;
    font-weight: 550;
    white-space: nowrap;
  }
  
  .alp-badge {
    background-color: var(--alp);
    color: white;
  }
  
  .grn-badge {
    background-color: var(--grn);
    color: black;
  }
  
  .ind-badge {
    background-color: var(--ind);
    color: white;
  }
  
  .indx-badge {
    background-color: var(--indx);
    color: white;
  }
  
  .kap-badge {
    background-color: var(--kap);
    color: white;
  }
  
  .lnp-badge {
    background-color: var(--lnp);
    color: white;
  }
  
  .lib-badge {
    background-color: var(--lib);
    color: white;
  }
  
  .nat-badge {
    background-color: var(--nat);
    color: white;
  }
  
  .on-badge {
    background-color: var(--on);
    color: black;
  }
  
  .oth-badge {
    background-color: var(--oth);
    color: white;
  }
  
  .uap-badge {
    background-color: var(--uap);
    color: black;
  }
  