.summary {
    text-align: center;
    max-width: 840px;
    width: 100%;
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.formationOfGovernmentTitle {
    font-size: 20px;
    background-color: #CCCCCC !important;
    border-radius: 20px 20px 0px 0px !important;
}

.formationOfGovernmentBody {
    padding: 0px !important;
    background-color: #CCCCCC !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.formationOfGovernmentHorz {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.formationOfGovernmentTopList {
    border-color: #CCCCCC !important;
    width: 100%;
}

.formationOfGovernmentTopItem {
    border-color: #CCCCCC;
}

.formationOfGovernmentSubItem {
    border-color: #AAAAAA;
    padding: 6px 20px !important;
    font-size: 14px;
}

.formationOfGovernmentDescription {
    padding: 0px 0px;
}

.formationOfGovernmentExpand {
    padding: 0px 0px 0px 8px !important;
    cursor: pointer;
    font-size: 14px !important;
    color: black !important;
    border-style: none !important;
    background-color: transparent !important;
}

.majorRowArranger {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropdown-toggle::after {
    display:none !important;
}

.alert {
    margin: 0px !important;
}

.alert p {
    margin: 0px !important;
}