.summary {
    text-align: center;
    max-width: 570px;
    width: 100%;
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.seatTotalsTitle {
    font-size: 20px;
    background-color: #CCCCCC !important;
    border-radius: 20px 20px 0px 0px !important;
}

.seatTotalsBody {
    padding: 0px !important;
    background-color: #CCCCCC !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seatTotalsSubHeading {
    font-size: 16px;
    font-weight: bold;
    background-color: #CCCCCC !important;
    text-align: center;
    width: 100%;
}

.seatTotalsList {
    border-color: #CCCCCC !important;
    width: 100%;
}

.seatTotalsItem {
    border-color: #CCCCCC;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    height: '100%';
    justify-content: space-between;
}

.seatTotalsNote {
    text-align: center;
    padding: 2px !important;
    font-size: 15px;
    background-color: #FFFFFF !important;
}

.alert {
    margin: 0px !important;
}

.alert p {
    margin: 0px !important;
}

.rowLeftSection {
    display: flex;
    flex-direction: row;
    width: 165px;
    justify-content: space-around;
    margin: 0px auto;
}

.rowParty {
    width: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowNumber {
    width: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowDash {
    width: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}