.summary {
    text-align: center;
    max-width: 700px;
    width: 100%;
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.voteTotalsTitle {
    font-size: 20px;
    background-color: #CCCCCC !important;
    border-radius: 20px 20px 0px 0px !important;
}

.voteTotalsBody {
    padding: 0px !important;
    background-color: #FFFFFF !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.voteTotalsSubHeading {
    font-size: 16px;
    font-weight: bold;
    background-color: #DDDDDD !important;
    text-align: center;
    width: 100%;
}

.voteTotalsList {
    width: 100%;
    border-color: #CCCCCC !important;
}

.voteTotalsItem {
    border-color: #CCCCCC;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    height: '100%';
    justify-content: space-between;
}

.voteTotalsNote {
    text-align: center;
    padding: 2px !important;
    font-size: 15px;
    background-color: #FFFFFF !important;
}

@supports (-webkit-touch-callout: none) {
    .voteTotalsItem {
        font-size: 15px;
    }
}

.alert {
    margin: 0px !important;
}

.alert p {
    margin: 0px !important;
}

.rowLeftSection {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-around;
    margin: 0px auto;
}

.rowParty {
    width: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowPercentage {
    width: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowDash {
    width: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}