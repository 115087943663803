.site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.summary {
    text-align: center;
    max-width: 500px;
    width: 100%;
    margin: 0px auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.archiveListTitle {
    font-size: 20px;
    background-color: #CCCCCC !important;
    border-radius: 20px 20px 0px 0px !important;
}

.archiveListBody {
    padding: 0px !important;
    background-color: #CCCCCC !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.archiveList {
    border-color: #CCCCCC !important;
    text-align: center;
    width: 100%;
}

.archiveListItem {
    border-color: #CCCCCC;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.archiveListFilters {
    padding: 2px !important;
    font-size: 17px;
    background-color: #E3E3E3 !important;
}