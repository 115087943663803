.summary {
    text-align: center;
    max-width: 600px;
    width: 100%;
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.seatsTitle {
    font-size: 20px;
    font-weight: bold;
    background-color: #CCCCCC !important;
    border-radius: 20px 20px 0px 0px !important;
}

.seatsBody {
    padding: 0px !important;
    background-color: #CCCCCC !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seatsList {
    border-color: #CCCCCC !important;
    text-align: center;
    width: 100%;
    padding: 0px;
}

.seatsItem {
    white-space: pre;
    border-color: #CCCCCC;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    height: '100%';
    background-color: #E3E3E3 !important;
    justify-content: space-between;
    width: 100%;
}

.returnToMain {
    padding: 2px !important;
    font-size: 17px;
    background-color: #E3E3E3 !important;
    width: 100%;
    text-align: center;
}

.seatsSubitem {
    white-space: pre;
    border-color: #CCCCCC;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF !important;
    justify-content: space-between;
    padding: 8px 16px !important;
}

.seatOptions {
    padding: 2px !important;
    display: flex !important;
    flex-flow: row wrap;
    justify-content: space-evenly;
    font-size: 17px;
    background-color: #E3E3E3 !important;
}

.seatsSubheading {
    padding: 2px !important;
    font-size: 17px;
    width: 100%;
    background-color: #E3E3E3 !important;
}

.seatsNote {
    padding: 2px !important;
    font-size: 13px;
    width: 100%;
    background-color: #FFFFFF !important;
}

.seatsTcpScenarioHeading {
    padding: 2px !important;
    font-size: 16px;
    width: 100%;
    background-color: #EEEEEE !important;
}

.seatsLeft {
    width: 220px;
    margin: 0px auto;
}

.seatsTopLeft {
    max-width: 300px;
    margin: 0px auto;
}

.seatsLink {
    cursor: pointer;
}

.seatsMore {
    background-color: #FFFFFF !important;
    white-space: normal;
    display: flex !important;
    flex-direction: column;
    font-size: 14px;
    margin: 0px auto;
    width: 100%;
}

.seatsWinStatement {
    padding: 3px 0px;
}

.alert {
    width: 100%;
    margin: 0px !important;
}

.textLeft {
    text-align: left;
}

.alert p {
    margin: 0px !important;
}

.rowLeftSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0px auto;
}

.rowParty {
    width: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowPercentage {
    width: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowDash {
    width: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowDash {
    width: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 420px){
    .candidateName {
        display: none;
    }
}

.altCandidateName {
    margin: 0px auto;
}

@media (min-width: 421px){
    .altCandidateName {
        display: none;
    }
}