.pageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-image: url('./assets/background.png');
  background-position: center;
  background-size: cover;
  position: relative;
}

.content {
  background: white;
  box-shadow: 0px 6px 13px #00000029;
  max-width: 100%;
  position: relative;
  padding: 20px 20px 20px 20px;
}

.border {
  background: linear-gradient(to right, var(--softblue) 20px, transparent 20px) 0
      0,
    linear-gradient(to right, var(--softblue) 20px, transparent 20px) 0 100%,
    linear-gradient(to left, var(--softblue) 20px, transparent 20px) 100% 0,
    linear-gradient(to left, var(--softblue) 20px, transparent 20px) 100% 100%,
    linear-gradient(to bottom, var(--softblue) 20px, transparent 20px) 0 0,
    linear-gradient(to bottom, var(--softblue) 20px, transparent 20px) 100% 0,
    linear-gradient(to top, var(--softblue) 20px, transparent 20px) 0 100%,
    linear-gradient(to top, var(--softblue) 20px, transparent 20px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 43px 100%;
  padding: 20px;
}
