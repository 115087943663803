.customTooltip {
    border: 1px solid;
    padding: 4px;
    border-color: #444444;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 16px;
}

.customTooltip p {
    margin: 0px;
}

.customTooltip .smallTooltipText {
   font-size: 13px;
}

.customTooltip .innerProbsText {
   color: #333333;
}

.customTooltip .outerProbsText {
   color: #888888;
}

.voteChartOptions {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: flex !important;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.chartNote {
    text-align: center;
}