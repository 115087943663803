.summary {
    text-align: center;
    max-width: 700px;
    width: 100%;
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.historyTitle {
    font-size: 20px;
    background-color: #CCCCCC !important;
    border-radius: 20px 20px 0px 0px !important;
}

.historyBody {
    padding: 0px !important;
    background-color: #FFFFFF !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.historyList {
    width: 100%;
    border-color: #CCCCCC !important;
}

.historyOptions {
    padding: 2px !important;
    font-size: 17px;
    background-color: #E3E3E3 !important;
    text-align: center;
    display: flex !important;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.customTooltip {
    border: 1px solid;
    padding: 4px;
    border-color: #444444;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 16px;
}

.alert {
    margin: 0px !important;
}

.alert p {
    margin: 0px !important;
}

.customTooltip p {
    margin: 0px;
}

.customTooltip .smallTooltipText {
   font-size: 13px;
}

.customTooltip .innerProbsText {
   color: #333333;
}

.customTooltip .outerProbsText {
   color: #888888;
}