
.forecastTitle {
    font-size: 22px;
    text-align: center;
    padding: 2px 8px;
    border-radius: 10px;
    background-color: #DDDDDD;
}

.forecastUpdateInfo {
    font-size: 11px;
    text-align: center;
    background-color: white;
}