.site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
}