.forecastAlert {
    text-align: center;
    max-width: 710px;
    /*width: 710px !important;*/
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.forecastAlert p {
    margin: 0px;
}

.nowcastAlert {
    text-align: center;
    max-width: 100%;
    width: 710px !important;
    margin: auto;
    border-color: #CCCCCC;
    border-radius: 20px 20px 2px 2px !important;
}

.nowcastAlert p {
    margin: 0px;
}

.firstPara {
    display: flex;
    flex: row;
}

.largeInfo {
    width: 36px;
    height: 36px;
    margin: 1px;
}

.largeWarning {
    width: 36px;
    height: 36px;
    margin: 1px;
}

.warningIcon {
    width: 22px;
}

.infoIcon {
    width: 22px;
}