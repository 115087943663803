.customTooltip {
    border: 1px solid;
    padding: 4px;
    border-color: #444444;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 16px;
}

.customTooltip p {
    margin: 0px;
}

.legendLabel {
    color: #333333;
}

/* Hack to handle different font used on iOS */
@supports (-webkit-touch-callout: none) {
    .legendLabel {
        font-size: 15px;
    }
}