
.infoIconBack {
    border: none;
    background: none;
    padding: 1px;
}

.infoIcon {
    width: 22px;
    height: 22px;
}

.infoIconLarge {
    width: 36px;
    height: 36px;
}